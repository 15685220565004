export const getUserEmail = (): string => {
  const token =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  return token.idToken?.claims?.email || "";
};

export const getUserRoles = (): string[] => {
  const token =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  return token.accessToken?.claims?.groups || [];
};

export const checkIfAdmin = (): boolean => {
  const userRoles = getUserRoles();

  return userRoles.includes("vmsops_admin");
};

export const isAuthorizedRoute = (pathname: string): boolean => {
  const userRoles = getUserRoles();

  const isAuthorized: {
    [key: string]: boolean;
  } = {
    "/invoices/ap":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_ap"),
    "/invoices/arnonvms":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_ar"),
    "/invoices/arvms":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_ar"),
    "/invoices/pwc-te":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_pwc"),
    "/invoices/s4-upload":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_s4"),
    "/invoices/generate-invoice":
      userRoles.includes("vmsops_admin") || userRoles.includes("vmsops_ar"),
    "/invoices/gahi": userRoles.includes("vmsops_admin"),
    "/invoices/cash-app":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/normal_expense":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/special_expense":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/company_config":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/time_mapping":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/global_config":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/generate_invoice_pdf":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/schedule_batch":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/fee_splitting":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
    "/config/client_template":
      userRoles.includes("vmsops_admin") ||
      userRoles.includes("vmsops_ap") ||
      userRoles.includes("vmsops_ar") ||
      userRoles.includes("vmsops_pwc") ||
      userRoles.includes("vmsops_s4"),
  };

  return isAuthorized[pathname];
};
