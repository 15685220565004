export function downloadFile(
  newBlob: Blob | MediaSource,
  fileName: string,
): void {
  const url = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);

  link.setAttribute("target", "_blank");

  document.body.appendChild(link);

  link.click();
  window.URL.revokeObjectURL(url);
}

export function includesStatus(statusArr: string[], status: string): boolean {
  if (status.startsWith("EBDR_DUPLICATE_")) {
    return statusArr.includes("EBDR_DUPLICATE_");
  } else {
    return statusArr.includes(status);
  }
}

export function prependZeros(number: string | number, digits: number): string {
  const numberString = number.toString().trim();

  let zerosToAdd = digits - numberString.length;

  zerosToAdd = zerosToAdd > 0 ? zerosToAdd : 0;
  const resultString = "0".repeat(zerosToAdd) + numberString;

  return resultString;
}

export function formatDate(dateString: string): string {
  if (!dateString || !dateString.match(/\d{4}-\d{2}-\d{2}/)) {
    return "";
  }

  const [year, month, day] = dateString.split("-");

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${months[parseInt(month) - 1]} ${day} ${year}`;
}

export function formatHour(hour: number | null): string {
  if (typeof hour !== "number" || hour < 0 || !Number.isInteger(hour)) {
    return "";
  }

  return hour === 1 ? "1 Hour" : `${hour} Hours`;
}

export function countInvoiceIds(
  includedInvoiceIds: number[],
  includedInvoiceIdRanges: Array<{
    startInvoiceId: number;
    endInvoiceId: number;
  }>,
  excludedInvoiceIds: number[],
  excludedInvoiceIdRanges: Array<{
    startInvoiceId: number;
    endInvoiceId: number;
  }>,
): number {
  function processRanges(
    set: Set<number>,
    ranges: Array<{ startInvoiceId: number; endInvoiceId: number }>,
  ): void {
    ranges.forEach((range) => {
      for (let i = range.startInvoiceId; i <= range.endInvoiceId; i++) {
        set.add(i);
      }
    });
  }

  const includedSet = new Set(includedInvoiceIds);
  const excludedSet = new Set(excludedInvoiceIds);

  processRanges(includedSet, includedInvoiceIdRanges);
  processRanges(excludedSet, excludedInvoiceIdRanges);

  let finalCount = 0;

  includedSet.forEach((id) => {
    if (!excludedSet.has(id)) {
      finalCount++;
    }
  });

  return finalCount;
}

export function getXMonthsOlderDate(months: number): Date {
  const date = new Date();

  date.setMonth(date.getMonth() - months);

  return date;
}

export function getMMDDYYYY(date: Date): string {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
