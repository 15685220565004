import React from "react";
import { NavLink } from "react-router-dom";

import { isAuthorizedRoute } from "../../auth/validateUser";
import { features } from "../../constants/features";
import tabValues from "../../data/TabSection.json";
import Tooltip from "../Tooltip";

const { isS4UploadRouteEnabled } = features.s4Upload;
const { isConsolidatedInvoiceRouteEnabled } = features.ConsolidatedInvoice;

const validTabs = tabValues.filter(({ id }) => {
  if (id === 5 && !isS4UploadRouteEnabled) return false;
  if (id === 6 && !isConsolidatedInvoiceRouteEnabled) return false;

  return true;
});

interface IProps {
  id: number;
  name: string;
  link: string;
  description?: string;
}

const InvoiceTabs: React.FC = () => {
  const tabs = validTabs.filter(({ link }) => isAuthorizedRoute(link));

  return (
    <>
      <ul className="flex">
        {tabs.map((item: IProps) => {
          return (
            <NavLink
              key={item.id}
              to={item.link}
              className={({ isActive }) =>
                "flex items-center space-x-2 py-3 w-max px-7 font-bold text-center cursor-pointer rounded-tr-md rounded-tl-md bg-[#211551] border-r-2 " +
                (isActive
                  ? " bg-white -mt-0.5 shadow-tab text-[#211551]/100 "
                  : " text-white ")
              }
            >
              <span>{item.name}</span>
              {item.description && (
                <Tooltip type={2} title={item.description} placement="bottom" />
              )}
            </NavLink>
          );
        })}
      </ul>
    </>
  );
};

export default InvoiceTabs;
