import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  cashAppRunHistoryDetails,
  uploadBankFileForm,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import { s4RunHistoryStatusFilter } from "../../constants/s4upload";
import useStore from "../../store/useStore";
import { getMMDDYYYY, getXMonthsOlderDate } from "../../utils";

import HistorySection from "./HistorySection";
import InitiateSection from "./InitiateSection";

// const RUN_HISTORY_CONST: cashAppRunHistoryDetails[] = [
//   // check
//   {
//     id: 9541,
//     bankReportFileName: "BANK_REPORT_01-11-2025.xlsx",
//     status: "STEP 3/3 COMPLETED",
//     outputFileLocation: "9541_BANK_REPORT_01-11-2025.xlsx",
//     remarks: "Report Generated",
//     startedBy: "jprabahar@mbopartners.com",
//     startedAt: {
//       value: "Jan 11 2025, 09:13 AM",
//     },
//     sourceFileLocation: "",
//   },
//   {
//     id: 9540,
//     bankReportFileName: "BANK_REPORT_01-09-2025.xlsx",
//     status: "STEP 3/3 COMPLETED",
//     outputFileLocation: "9540_BANK_REPORT_01-09-2025.xlsx",
//     remarks: "Report Generated",
//     startedBy: "jprabahar@mbopartners.com",
//     startedAt: {
//       value: "Jan 09 2025, 12:37 AM",
//     },
//     sourceFileLocation: "",
//   },
//   {
//     id: 9539,
//     bankReportFileName: "BANK_REPORT_01-07-2025.xlsx",
//     status: "STEP 3/3 COMPLETED",
//     outputFileLocation: "9539_BANK_REPORT_01-07-2025.xlsx",
//     remarks: "Report Generated",
//     startedBy: "jprabahar@mbopartners.com",
//     startedAt: {
//       value: "Jan 07 2025, 08:26 AM",
//     },
//     sourceFileLocation: "",
//   },
// ];

const CashApp: React.FC = () => {
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);

  const [inputVal, setInputVal] = useState<uploadBankFileForm>({
    file: undefined,
    fromDate: getXMonthsOlderDate(6),
    toDate: new Date(),
  });

  const [runHistory, setRunHistory] = useState<cashAppRunHistoryDetails[]>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [pageSize, setPageSize] = useState<number>(5);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [statusFilter, setStatusFilter] = useState<string>(
    s4RunHistoryStatusFilter.ALL,
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const pageNo = paginationModel.page;
  const pageNoRef = useRef(pageNo);
  const statusFilterRef = useRef(statusFilter);
  const filterModelRef = useRef(filterModel);
  const sortModelRef = useRef(sortModel);

  const buttonDisabled =
    !inputVal.file || !inputVal.fromDate || !inputVal.toDate;

  const _runHistory = runHistory;

  const handleFileUpload = (file: File): void => {
    setInputVal((inputVal) => ({ ...inputVal, file }));
  };

  const closeFileUploaded = (): void => {
    setInputVal((inputVal) => ({ ...inputVal, file: undefined }));
  };

  const handleDateChange = (date: Date, name: string): void => {
    setInputVal((inputVal) => ({ ...inputVal, [name]: date }));
  };

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData();

    const data = {
      reconciliationStartDate: inputVal?.fromDate
        ? getMMDDYYYY(inputVal?.fromDate)
        : "",
      reconciliationEndDate: inputVal?.toDate
        ? getMMDDYYYY(inputVal?.toDate)
        : "",
    };

    const json = JSON.stringify(data);

    const jsonData = new Blob([json], {
      type: "application/json",
    });

    formData.append("cashAppRequest", jsonData);
    formData.append("file", inputVal.file || "");

    try {
      const request = InitiateInvoiceApi.uploadCashAppFile(formData);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length !== 0) {
        const { message } = response.data.errors[0];

        setLoader(false);
        enableToast({
          pos: "top",
          message: message || "File not uploaded",
          type: "error",
        });

        return;
      }

      setInputVal({
        file: undefined,
        fromDate: getXMonthsOlderDate(6),
        toDate: new Date(),
      });
      setLoader(false);

      const { executionDetail }: { executionDetail: cashAppRunHistoryDetails } =
        response.data.data.process;

      enableToast({
        pos: "top",
        message: `File uploaded successfully, Run ID: ${executionDetail.id}`,
        type: "success",
      });

      getRunHistory();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getRunHistory = useCallback(
    async (
      _pageNo?: number,
      _status?: string,
      _filterModel?: GridFilterModel,
      _sortModel?: GridSortModel,
    ): Promise<void> => {
      setLoader(true);

      const pNo = _pageNo ?? pageNoRef.current;
      const status = _status ?? statusFilterRef.current;
      const fModel = _filterModel ?? filterModelRef.current;
      const sModel = _sortModel ?? sortModelRef.current;
      const idFilter = fModel?.items?.find(({ field }) => field === "id");

      const startedAtFilter = fModel?.items?.find(
        ({ field }) => field === "startedAt",
      );

      const startedByFilter = fModel?.items?.find(
        ({ field }) => field === "startedBy",
      );

      const filterId: string = [undefined, null, ""].includes(idFilter?.value)
        ? ""
        : idFilter?.value;

      const filterStartedAt: string = [undefined, null, ""].includes(
        startedAtFilter?.value,
      )
        ? ""
        : startedAtFilter?.value;

      const filterStartedBy: string = [undefined, null, ""].includes(
        startedByFilter?.value,
      )
        ? ""
        : startedByFilter?.value;

      const sortValue: string = sModel?.[0]?.field ? sModel[0].field : "id";
      const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

      try {
        const fId = filterId ? `run_id in (${filterId})` : "";

        const fStatus =
          status && status !== s4RunHistoryStatusFilter.ALL
            ? `status in (${status})`
            : "";

        const fStartedAt = filterStartedAt
          ? `STARTED_DATE range (${filterStartedAt},${filterStartedAt})`
          : "";

        const fStartedBy = filterStartedBy
          ? `started_by in (${filterStartedBy})`
          : "";

        const pageInfo = { pageNo: pNo, pageSize };

        const getRequest = InitiateInvoiceApi.getRunHistory(
          "CASH_APP",
          sortValue,
          sortOrder,
          {
            fId,
            fStatus,
            fStartedAt,
            fStartedBy,
          },
          pageInfo,
        );

        const getResponse = await ResponseAPI.performRequest(getRequest);

        const {
          executionDetails = [],
          totalCount = 0,
        }: {
          executionDetails: cashAppRunHistoryDetails[];
          totalCount: number;
        } = getResponse?.data?.data?.process || {};

        setRunHistory(executionDetails);
        setPaginationModel({ page: pNo, pageSize });
        setStatusFilter(status);
        setFilterModel(fModel);
        setSortModel(sModel);
        setTotalRowCount(totalCount);

        if (getResponse.data.errors.length > 0) {
          enableToast({
            pos: "top",
            message: getResponse.data.errors[0].message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoader(false);
    },
    [pageSize],
  );

  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  useEffect(() => {
    statusFilterRef.current = statusFilter;
  }, [statusFilter]);

  useEffect(() => {
    filterModelRef.current = filterModel;
  }, [filterModel]);

  useEffect(() => {
    sortModelRef.current = sortModel;
  }, [sortModel]);

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({
        pos: "top",
        message: "",
        type: "",
      });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    getRunHistory();
  }, [getRunHistory]);

  useEffect(() => {
    enableToast({
      pos: "top",
      message: "",
      type: "",
    });
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      <InitiateSection
        inputVal={inputVal}
        handleFileUpload={handleFileUpload}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        buttonDisabled={buttonDisabled}
        closeFileUploaded={closeFileUploaded}
      />
      <HistorySection
        runHistory={_runHistory}
        totalRowCount={totalRowCount}
        pageNo={pageNo}
        pageSize={pageSize}
        filterModel={filterModel}
        sortModel={sortModel}
        getRunHistoryFunc={getRunHistory}
        paginationModel={paginationModel}
        onPaginationModelChange={({ page, pageSize }) => {
          getRunHistory(page, undefined, undefined);
          setPageSize(pageSize);
        }}
        onFilterChange={(filterModel) => {
          getRunHistory(0, undefined, filterModel);
        }}
        onSortModelChange={(sortModel) => {
          getRunHistory(undefined, undefined, undefined, sortModel);
        }}
      />
    </>
  );
};

export default CashApp;
