import React, { SyntheticEvent } from "react";

import { uploadBankFileForm } from "../../@types/assetTypes/ap";
import Button from "../../components/Button";
import Datepicker from "../../components/Datepicker";
import FileUpload from "../../components/FileUpload";
import Textlabel from "../../components/Textlabel";

interface CashAppProps {
  inputVal: uploadBankFileForm;
  handleFileUpload: (file: File) => void;
  handleDateChange: (e: Date, name: string) => void;
  handleSubmit: (e: SyntheticEvent) => void;
  buttonDisabled: boolean;
  closeFileUploaded: () => void;
}

const InitiateSection: React.FC<CashAppProps> = ({
  inputVal,
  handleFileUpload,
  handleDateChange,
  handleSubmit,
  buttonDisabled,
  closeFileUploaded,
}) => {
  return (
    <div className="py-5 shadow-custom">
      <form className="my-10 px-5 mx-auto">
        <div className="flex flex-col items-center w-full gap-7">
          <div className="flex flex-wrap w-full gap-10 items-center justify-center">
            <div className="relative">
              <div className="flex gap-4 items-center justify-between">
                <Textlabel label="Upload Bank File" mandatory />
                <FileUpload
                  acceptedFormats={[".xlsx"]}
                  handleFileUpload={handleFileUpload}
                />
              </div>
              {inputVal.file && (
                <div className="absolute -bottom-8 right-0 w-96 flex justify-end items-center">
                  <span className="w-full truncate">{inputVal.file?.name}</span>
                  <span
                    onClick={closeFileUploaded}
                    className="font-mono font-bold cursor-pointer text-[#211551]/40 leading-4 hover:text-[#211551] ml-3"
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col items-end gap-6">
              <div className="flex items-center gap-4">
                <Textlabel label="From Date" mandatory />
                <Datepicker
                  value={inputVal.fromDate || null}
                  onChange={(e) => handleDateChange(e, "fromDate")}
                  className="shadow-custom px-1 py-1.5 shadow-blue-200"
                  showClearIcon
                  placeholderText="mm / dd / yyyy"
                  maxDate={inputVal.toDate || undefined}
                />
              </div>
              <div className="flex items-center gap-4">
                <Textlabel label="To Date" mandatory />
                <Datepicker
                  value={inputVal.toDate || null}
                  onChange={(e) => handleDateChange(e, "toDate")}
                  className="shadow-custom px-1 py-1.5 shadow-blue-200"
                  showClearIcon
                  placeholderText="mm / dd / yyyy"
                  minDate={inputVal.fromDate || undefined}
                  maxDate={new Date()}
                />
              </div>
              <div className="italic text-sm">Default: 6 months look back</div>
            </div>
          </div>
          <div className="flex mt-5 w-full justify-center">
            <Button
              label="Run Report"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonDisabled}
              customWidth="w-96 max-w-full"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InitiateSection;
