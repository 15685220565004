import {
  DataGrid,
  GridRowsProp,
  MuiEvent,
  GridColDef,
  GridCellParams,
  GridRowId,
  GridRenderCellParams,
  GridFilterModel,
  getGridNumericOperators,
  GridSortModel,
  GridColumnHeaderParams,
  GridPaginationModel,
  getGridDateOperators,
  GridFilterInputDateProps,
  GridFilterInputDate,
  getGridStringOperators,
} from "@mui/x-data-grid";
import React, { useState } from "react";

import { cashAppRunHistoryDetails } from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import StatusTitle from "../../components/ColumnHeaders/StatusTitle";
import TimeStampTitle from "../../components/ColumnHeaders/TimeStampTitle";
import TooltipContent from "../../components/ColumnHeaders/TooltipContent";
import ConfirmModal from "../../components/Modalwindow";
import Refresh from "../../components/RefreshButton";
import SectionContainer from "../../components/SectionConatiner";
import statusDetail from "../../data/CashApp.json";
import useStore from "../../store/useStore";
import { downloadFile } from "../AP/Ap.logichandler";
import Empty from "../S4Upload/components/Empty";
import Toolbar from "../S4Upload/components/Toolbar";

const columns: GridColDef[] = [
  {
    field: "id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
    headerName: "Run ID",
    filterOperators: getGridNumericOperators().filter(
      (operator) => operator.value === "=",
    ),
    valueFormatter: (params) => params.value,
  },
  {
    field: "bankReportFileName",
    headerName: "Original Bank File",
    width: 250,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      if (!params.value) return <></>;
      else {
        return (
          <span className="text-[#1976d2] overflow-hidden text-ellipsis">
            {params.value}
          </span>
        );
      }
    },
  },
  {
    field: "reconciliationStartDate",
    headerName: "From Date",
    width: 100,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
    valueFormatter: (params) => params.value,
  },
  {
    field: "reconciliationEndDate",
    headerName: "To Date",
    width: 100,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
    valueFormatter: (params) => params.value,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <StatusTitle
          title={params.colDef.headerName}
          detail={statusDetail}
          hideCustomSort
        />
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipContent title={params.value} />;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "outputFileLocation",
    headerName: "Reconciliation Report",
    width: 250,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      if (!params.value) return <></>;
      else {
        return (
          <span className="text-[#1976d2] overflow-hidden text-ellipsis">
            Download File
          </span>
        );
      }
    },
  },
  {
    field: "remarks",
    headerName: "Comments",
    width: 250,
    sortable: false,
    filterable: false,
  },
  // {
  //   field: "reInitiate",
  //   headerName: "Re-Run",
  //   width: 80,
  //   sortable: false,
  //   filterable: false,
  //   align: "center",
  //   renderCell: (params) => {
  //     if (params.value !== "NA") {
  //       return <BiRefresh className="h-7 w-7 rotate-90 text-[#211551]" />;
  //     }
  //   },
  //   disableExport: true,
  // },
  {
    field: "processingTime",
    headerName: "Run Duration",
    width: 150,
    cellClassName: "break-words",
    sortable: false,
    filterable: false,
    valueFormatter: (params) => params.value,
  },
  {
    field: "startedAt",
    headerName: "Run Time (UTC)",
    width: 200,
    sortable: false,
    filterOperators: getGridDateOperators()
      .filter((operator) => operator.value === "is")
      .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent
          ? (props: GridFilterInputDateProps) => (
              <GridFilterInputDate {...props} variant="standard" />
            )
          : undefined,
      })),
    renderHeader: (params) => {
      return <TimeStampTitle title={params.colDef.headerName} />;
    },
  },
  {
    field: "startedBy",
    headerName: "Initiated By",
    minWidth: 300,
    sortable: false,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "equals",
    ),
  },
];

interface historyProps {
  runHistory: cashAppRunHistoryDetails[];
  totalRowCount: number;
  paginationModel: GridPaginationModel;
  pageSize: number;
  pageNo: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  getRunHistoryFunc: (pageNo: number) => void;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  onFilterChange: (filterModel: GridFilterModel) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

const Historysection: React.FC<historyProps> = ({
  runHistory,
  totalRowCount,
  paginationModel,
  pageNo,
  pageSize,
  filterModel,
  sortModel,
  getRunHistoryFunc,
  onPaginationModelChange,
  onFilterChange,
  onSortModelChange,
}) => {
  const enableLoader = useStore((state) => state.enableLoader);
  const setLoader = useStore((state) => state.setLoader);
  const enableToast = useStore((state) => state.enableToast);
  const [rerunProcessId, setRerunProcessId] = useState<GridRowId>("");
  const [showModal, setShowModal] = useState<Boolean>(false);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);

  const reInititateRunHistory = async (runID: GridRowId): Promise<void> => {
    setLoader(true);

    try {
      const getRequest = InitiateInvoiceApi.reInitiateCashApp(runID);
      const getResponse = await ResponseAPI.performRequest(getRequest);

      if (getResponse.data.errors.length !== 0) {
        setLoader(false);
        enableToast({
          message: getResponse.data.errors[0].message,
          type: "error",
        });

        return;
      }

      setLoader(false);
      const newRunID: number = getResponse.data.data.process.executionDetail.id;

      enableToast({
        message: `Re-initiated, Run ID: ${newRunID}`,
        type: "success",
      });
      getRunHistoryFunc(0);
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const rows: GridRowsProp = runHistory.map((item) => {
    const isErrorStatus = item?.status?.toLowerCase()?.includes("fail");

    return {
      ...item,
      status: [
        isErrorStatus ? "ERROR" : "-",
        item.status,
        isErrorStatus ? item.remarks : "",
      ]
        .filter(Boolean)
        .join(":"),
      startedAt: item?.startedAt?.value || "",
    };
  });

  const handleClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ): void => {
    event.preventDefault();
    const { field, row, id } = params;
    const value = row[field];

    if (field === "bankReportFileName" && value) {
      const fileName = value.split("/").pop();

      downloadFile(id, fileName, "input");
    }

    if (field === "outputFileLocation" && value) {
      const paramLength = value.split(",").length;
      const fileName = value.split("/").pop();

      downloadFile(id, paramLength === 1 ? fileName : `${id}_CashApp.zip`);
    }

    if (field === "reInitiate" && value !== "NA") {
      setRerunProcessId(id);
      setShowModal(true);
    }
  };

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState,
    );
  }, [totalRowCount, setRowCountState]);

  return (
    <>
      <SectionContainer
        title="Run History"
        className="w-full mx-auto mt-14 px-10"
      >
        <div className="flex justify-end">
          <Refresh refresh={() => getRunHistoryFunc(pageNo)} label="Refresh" />
        </div>
        <div
          className={[
            rowCountState < pageSize
              ? "max-h-[850px]"
              : pageSize === 5
                ? "min-h-[350px] max-h-[375px] h-[60vh] 2xl:h-[45vh]"
                : "min-h-[450px] max-h-[625px] h-[70vh] 2xl:h-[65vh]",
            "mx-auto my-5 border border-[#211551] rounded-md",
          ].join(" ")}
        >
          <DataGrid
            autoHeight={rowCountState < pageSize}
            hideFooter={rowCountState < pageSize}
            loading={enableLoader}
            sortingOrder={["desc", "asc"]}
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
            rowCount={rowCountState}
            rows={rows}
            columns={columns}
            slots={{ toolbar: Toolbar, loadingOverlay: Empty }}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[5, 10]}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            filterModel={filterModel}
            sortModel={sortModel}
            onFilterModelChange={onFilterChange}
            onSortModelChange={onSortModelChange}
            sx={{
              "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
                borderBottomWidth: 0,
              },
              "& .MuiDataGrid-cell:hover": {
                // color: "primary.main",
                cursor: "pointer",
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButtonBase-root": { color: "#211551" },
              },

              "& .MuiDataGrid-columnHeaders": {
                bgcolor: "#211551",
                color: "white",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiIconButton-sizeSmall": {
                  color: "white",
                },
              },
              "& .MuiDataGrid-row": { fontSize: "small" },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "17px",
              },
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & MuiDataGrid-columnHeader:focus-within":
                {
                  outline: "none",
                },
            }}
            getRowHeight={() => "auto"}
            isCellEditable={() => false}
            columnHeaderHeight={44}
            disableRowSelectionOnClick
            onCellClick={handleClick}
          />
        </div>
      </SectionContainer>
      {showModal && (
        <ConfirmModal closeModal={() => setShowModal(false)}>
          <>
            <div className="flex items-center justify-center">
              <div className="bg-white p-4">
                <p className="mb-4">
                  Would you like to re-run Run ID - {rerunProcessId}
                </p>
                <div className="flex justify-end">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => {
                      setShowModal(false);
                      reInititateRunHistory(rerunProcessId);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </>
        </ConfirmModal>
      )}
    </>
  );
};

export default Historysection;
